<template v-if="showForm">
  <div :id="'user' + user.id" class="user">
    <div class="user__header">
      <h6 class="text-left">
        {{ user.username.toUpperCase() }}
      </h6>
      <button
        type="button"
        class="close"
        aria-label="Close"
        @click="modifyUser"
        v-if="user.statusCheckbox"
      >
        <span aria-hidden="true" class="settings-user d-flex align-items-center"
          ><i class="fas fa-user-cog"></i
        ></span>
      </button>
    </div>
    <hr class="divider" />
    <div class="text-center">
      <br />
      <b>Email: </b>{{ user.email }}
      <br />
      <b-avatar
        class="icon-status"
        size="sm"
        :variant="rol == 'Available' ? 'success' : 'danger'"
        v-if="isDataCharged"
      ></b-avatar>
      <b>Name: </b>{{ user.name + " " + user.lastname }}<br />
      <div class="user__status">
        <b>Status: </b>
        <ToggleButton
          v-model="user.statusCheckbox"
          onLabel="Active"
          offLabel="Block"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          class="user-button-status p-button-rounded"
          iconPos="right"
          @click="
            user.status.app == 'Active'
              ? deleteUser(user.id)
              : restoreUser(user.id)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from "primevue/togglebutton";

export default {
  name: "User",
  components: {
    ToggleButton
  },
  props: {
    user: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      showForm: null,
      loggedUserId: null,
      rol: {},
      rolApp: {},
      isDataCharged: false
    };
  },
  async created() {
    await this.getStatusUser();
  },
  methods: {
    closeForm() {
      this.showForm = !this.showForm;
    },
    showFormUsers() {
      this.showForm = !this.showForm;
      this.$router.push({
        name: "Users",
        params: {
          option: "create"
        }
      });
    },
    modifyUser() {
      this.showForm = !this.showForm;
      this.$emit("modifyUser", this.user.id);
    },
    async deleteUser(id) {
      await this.$store.dispatch("users/deleteUser", {
        id: id
      });
      this.$emit("reload");
    },
    async restoreUser(id) {
      await this.$store.dispatch("users/restoreUser", {
        id: id
      });
      this.$emit("reload");
    },
    async getStatusUser() {
      let result = await this.$store.dispatch(
        "users/getUserById",
        this.user.id
      );
      this.rol = result.data.status.me;
      this.isDataCharged = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.user {
  @include card;
  margin: 10px;
  width: 300px;

  &__header {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h6 {
      margin: 0;
    }
  }

  &__status {
    margin: 10px 0px;
  }
}

.user-icon {
  color: $color-primary-button;
}

.delete-user {
  @include close-button($color-white);
}

.settings-user {
  font-size: 16px;
  color: $color-link;
}

.divider {
  margin: 5px;
}

.fa-sync-alt {
  margin-left: 2%;
}

.user-button-status {
  margin-right: -10%;
  width: 100px;
  height: 22px;
}

.icon-status {
  margin-right: 5px;
}
</style>
