<template>
  <div class="user-form">
    <h4 v-if="!showForm">
      {{
        this.$route.params.option == "create" ? "Create New User" : "Edit User"
      }}
    </h4>
    <!-- MESSAGE FOR ERRORS IN VALIDATE -->
    <Message severity="error" :closable="false" v-if="isError">{{
      msgError
    }}</Message>
    <!-- USER FORM -->
    <b-form class="user-form__form">
      <span class="p-float-label">
        <InputNumber
          class="input-user"
          v-model="form.document"
          required
          :useGrouping="false"
        ></InputNumber>
        <label>Document</label>
      </span>
      <span class="p-float-label">
        <InputText class="input-user" v-model="form.name" required></InputText>
        <label>Name *</label>
      </span>
      <span class="p-float-label">
        <InputText
          class="input-user"
          v-model="form.lastname"
          required
        ></InputText>
        <label>Lastname *</label>
      </span>
      <span class="p-float-label">
        <InputText
          class="input-user"
          v-model="form.email"
          required
          v-if="!showCreateInputs"
        ></InputText>
        <label v-if="!showCreateInputs">Email *</label>
      </span>
      <span class="p-float-label">
        <InputNumber
          class="input-user"
          v-model="form.phone"
          required
          :useGrouping="false"
        ></InputNumber>
        <label>Phone</label>
      </span>
      <span class="p-float-label">
        <InputText
          class="input-user"
          v-model="form.username"
          v-if="!showCreateInputs"
          required
        ></InputText>
        <label v-if="!showCreateInputs">Username *</label>
      </span>
      <span class="p-float-label">
        <Password
          class="input-user"
          v-model="form.password"
          v-if="!showCreateInputs"
          required
          toggleMask
          :feedback="false"
        ></Password>
        <label v-if="!showCreateInputs">Password *</label>
      </span>
      <div
        class="p-field-checkbox"
        v-for="(rolOption, index) in roles"
        :key="index"
      >
        <RadioButton
          class="radio-rol"
          :id="rolOption.name"
          :name="rolOption.name"
          :value="rolOption.id"
          v-model="form.rol_id"
          v-if="!showCreateInputs"
        />
        <label v-if="!showCreateInputs">{{ rolOption.name }}</label>
      </div>
      <!-- USER BUTTONS -->
      <div class="user-form__submit">
        <b-button
          variant="danger"
          class="cancel-send-new-user"
          @click="closeForm"
          >Cancel</b-button
        >
        <b-button
          class="send-new-user"
          @click="
            $route.params.option == 'create' ? createNewUser() : updateUser()
          "
        >
          {{ this.$route.params.option == "create" ? "Create" : "Edit" }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import InputNumber from "primevue/inputnumber";
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import RadioButton from "primevue/radiobutton";

export default {
  name: "Usersform",
  props: {
    userId: {
      type: Number,
      required: false
    },
    roles: {
      type: Array,
      required: false
    }
  },
  components: {
    Message,
    InputNumber,
    InputText,
    Password,
    RadioButton
  },
  data() {
    return {
      showForm: null,
      showCreateInputs: false,
      isError: false,
      msgError: "",
      form: {
        document: null,
        name: "",
        email: "",
        username: "",
        lastname: "",
        phone: null,
        rol_id: null,
        password: null
      },
      udpatedForm: {
        document: null,
        name: "",
        lastname: "",
        phone: null
      }
    };
  },
  async created() {
    this.showCreateInputs = false;
    if (this.$route.params.option == "edit") {
      this.showCreateInputs = true;
      let result = await this.$store.dispatch(
        "users/getUserById",
        this.$route.params.id
      );
      this.form.document = parseInt(result.data.document);
      this.form.name = result.data.name;
      this.form.lastname = result.data.lastname;
      this.form.phone = parseInt(result.data.phone);
    }
    //Assignment to temporary variables where we will know in the update if we change anything to the information already saved
    this.udpatedForm.document = this.form.document;
    this.udpatedForm.name = this.form.name;
    this.udpatedForm.lastname = this.form.lastname;
    this.udpatedForm.phone = this.form.phone;
  },
  methods: {
    closeForm() {
      this.$emit("closeForm", this.showForm);
    },
    getAllUsers() {
      this.$emit("userCreated");
    },
    //Validation of required fields
    validateCreate() {
      let emailRule = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      if (
        this.form.name == "" ||
        this.form.lastname == "" ||
        this.form.email == "" ||
        this.form.username == "" ||
        this.form.password == ""
      ) {
        this.isError = true;
        this.msgError = "Please check and fill in all required fields with *";
        return false;
      } else if (!emailRule.test(this.form.email)) {
        this.isError = true;
        this.msgError = "Please enter a valid email address";
        return false;
      } else {
        this.isError = false;
        this.msgError = "";
        return true;
      }
    },
    //Validation of required fields in the update view
    validateUpdate() {
      if (this.form.name == "" || this.form.lastname == "") {
        this.isError = true;
        this.msgError = "Please check and fill in all required fields with *";
        return false;
      } else {
        this.isError = false;
        this.msgError = "";
        return true;
      }
    },
    async createNewUser() {
      // Conditional to know if the user did not leave any field empty and if the mail is a correct format
      if (this.validateCreate()) {
        await this.$store.dispatch("users/createNewUser", this.form);
        this.getAllUsers();
        (this.form.document = null),
          (this.form.name = ""),
          (this.form.email = ""),
          (this.form.username = ""),
          (this.form.lastname = ""),
          (this.form.phone = null),
          (this.form.rol = null),
          (this.form.password = null),
          this.getAllUsers();
        this.$emit("closeForm");
      }
    },
    async updateUser() {
      // Conditional to know if the user has updated something
      if (
        this.form.document != this.udpatedForm.document ||
        this.form.name != this.udpatedForm.name ||
        this.form.lastname != this.udpatedForm.lastname ||
        this.form.phone != this.udpatedForm.phone
      ) {
        if (this.validateUpdate()) {
          await this.$store.dispatch("users/updateUser", {
            id: this.$route.params.id,
            body: this.form
          });
          this.getAllUsers();
          this.$emit("closeForm");
        }
      } else {
        this.$emit("closeForm");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-form {
  padding: 20px;

  &__form {
    margin-top: 10px;
  }

  .input-user {
    width: 100%;
  }

  &__submit {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .cancel-send-new-user {
      @include box-shadow;
    }

    .send-new-user {
      margin-left: 10px;
      width: 80px;
      @include primary-button;
    }
  }
}

::v-deep {
  & .p-float-label {
    width: 100%;
    margin-top: 30px;
  }

  .p-password-input {
    width: 100%;
  }

  .p-field-checkbox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    label {
      margin-bottom: 0px;
      margin-left: 5px;
    }
  }
}
</style>
