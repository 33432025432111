<template>
  <div :key="keyRender">
    <template v-if="show">
      <div class="tags">
        <!-- BUTTON CREATE NEW TAG  -->
        <b-button class="tags__button" size="sm" @click="showFormTag"
          >New Tag</b-button
        >
        <Splitter class="splitters-content">
          <SplitterPanel :size="75" class="p-d-flex p-ai-center p-jc-center">
            <div v-if="show" class="splitters-content__tags">
              <CardTags
                :tags="tags"
                @tagDeleted="showTags"
                @tagUpdate="tagUpdate"
              />
            </div>
          </SplitterPanel>
          <SplitterPanel :size="25" class="p-d-flex p-ai-center p-jc-center">
            <div class="splitters-content__form" v-if="show">
              <TagsForm @closeForm="closeForm" @tagCreated="showTags" />
            </div>
          </SplitterPanel>
        </Splitter>
      </div>
    </template>

    <template v-else>
      <!-- BUTTON CREATE NEW TAG  -->
      <b-button class="tags__button" size="sm" @click="showFormTag"
        >New Tag</b-button
      >
      <CardTags :tags="tags" @tagDeleted="showTags" @tagUpdate="tagUpdate" />
    </template>
  </div>
</template>

<script>
// <!-- IMPORTS  -->
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import TagsForm from "./TagsForm.vue";
import CardTags from "./CardTags.vue";

export default {
  name: "Tags",
  components: {
    Splitter,
    SplitterPanel,
    TagsForm,
    CardTags
  },
  data() {
    return {
      show: false,
      keyRender: 0,
      tags: null,
      isLoading: false
    };
  },
  async created() {
    await this.showTags();
  },
  methods: {
    closeForm() {
      this.show = !this.show;
    },
    showFormTag() {
      this.show = !this.show;
      this.$router
        .push({
          name: "Tags",
          params: {
            option: "create"
          }
        })
        .catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // logError(err);
          }
        });
    },
    tagUpdate(tag) {
      this.show = !this.show;
      this.$router
        .push({
          name: "Tags",
          params: {
            option: "update",
            id: tag.id,
            tag: tag
          }
        })
        .catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // logError(err);
          }
        });
    },
    async showTags() {
      let response = await this.$store.dispatch("dbTags/getTags");
      this.tags = response.data;
      this.keyRender += 1;
    }
  }
};
</script>
<style lang="scss" scoped>
.tags {
  width: 100%;
  margin-top: 15px;
  &__button {
    @include secondary-button;
    margin-top: 15px;
  }
}
.splitters-content {
  width: 100%;
  height: 78vh;
  &__tags {
    width: 100%;
    @extend %splitter-content;
  }
  &__form {
    width: 100%;
    @extend %splitter-content;
  }
}

::v-deep {
  .p-splitter {
    border: none;
  }
}

::v-deep .p-splitter .p-splitter-gutter {
  background-color: $color-white;
}

::v-deep .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background-color: transparent;
}
</style>
