<template>
  <div class="tags-form">
    <h4 v-if="!showForm">
      {{
        this.$route.params.option == "create" ? "Create New Tag" : "Edit Tag"
      }}
    </h4>
    <!-- MESSAGE FOR ERRORS IN VALIDATE -->
    <Message severity="error" :closable="false" v-if="isError">{{
      msgError
    }}</Message>
    <b-form class="tags-form__form">
      <span class="p-float-label">
        <InputText
          class="input-tag-name"
          v-model="form.name"
          required
        ></InputText>
        <label>Name</label>
      </span>
      <b-form-group
        id="input-group-2"
        class="input-tag-color"
        label="Color:"
        label-for="input-2"
      >
        <b-form-input
          v-model="form.color"
          input
          type="color"
          required
        ></b-form-input>
      </b-form-group>
      <div class="tags-form__submit">
        <b-button
          @click="closeForm"
          variant="danger"
          class="cancel-send-new-tag"
          >Cancel</b-button
        >
        <!-- TAG BUTTONS -->
        <b-button
          class="send-new-tag"
          @click="
            $route.params.option == 'create' ? createNewTag() : updateTag()
          "
        >
          {{ this.$route.params.option == "create" ? "Create" : "Edit" }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import Message from "primevue/message";
import InputText from "primevue/inputtext";

export default {
  name: "Tagsform",
  components: {
    Message,
    InputText
  },
  data() {
    return {
      showForm: null,
      isError: false,
      msgError: "",
      oldForm: {
        name: "",
        color: "#000000"
      },
      form: {
        name: "",
        color: "#000000"
      }
    };
  },
  created() {
    if (this.$route.params.option == "update") {
      this.form.name = this.$route.params.tag.name;
      this.form.color = this.$route.params.tag.color;
    }
    this.oldForm.name = this.form.name;
    this.oldForm.color = this.form.color;
  },
  methods: {
    closeForm() {
      this.$emit("closeForm", this.showForm);
    },
    getAllTags() {
      this.$emit("tagCreated");
    },
    validate() {
      if (this.form.name == "") {
        this.isError = true;
        this.msgError = "Please enter a name";
        return false;
      } else {
        this.isError = false;
        this.msgError = "";
        return true;
      }
    },
    async createNewTag() {
      if (this.validate()) {
        this.form.name = this.form.name.toUpperCase();
        await this.$store.dispatch("dbTags/createNewTag", this.form);
        this.getAllTags();
        this.form.name = "";
        this.form.color = "#000000";
        this.getAllTags();
        this.$emit("closeForm");
      }
    },
    async updateTag() {
      if (
        this.form.name != this.oldForm.name ||
        this.form.color != this.oldForm.color
      ) {
        if (this.validate()) {
          this.form.name = this.form.name.toUpperCase();
          await this.$store.dispatch("dbTags/updateTag", {
            id: this.$route.params.id,
            body: this.form
          });
          this.getAllTags();
          this.$emit("closeForm");
        }
      } else {
        this.$emit("closeForm");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tags-form {
  padding: 20px 40px;

  &__form {
    width: 100%;
    margin-top: 50px;
  }

  .input-tag-name {
    width: 100%;
  }

  .input-tag-color {
    margin-top: 15px;
  }

  &__submit {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .cancel-send-nuew-tag {
    @include box-shadow;
  }

  .send-new-tag {
    margin-left: 10px;
    width: 80px;
    @include primary-button;
  }
}
</style>
