<template>
  <div class="card-tags">
    <div class="tags-list">
      <div v-for="tag in tags" :key="tag.id" class="tags-list__card">
        <div class="tags-list__button">
          <!-- TAG DROPDOWN -->
          <b-dropdown
            dropup
            no-caret
            v-if="!tag.static"
            variant="link"
            toggle-class="text-decoration-none"
          >
            <template #button-content>
              <i class="fas fa-edit" v-if="!tag.static"></i>
            </template>
            <b-dropdown-item @click="deleteTag(tag.id)">Delete</b-dropdown-item>
            <b-dropdown-item @click="showFormTag(tag)">Update</b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- CARD TAGS -->
        <div>
          <span class="ml-2"><b>ID:</b> {{ tag.id }}</span
          ><br /><br />
          <b-icon
            icon="tag-fill"
            :style="'color:' + tag.color"
            class="ml-2"
          ></b-icon>
          <span class="ml-3">{{ tag.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTags",
  props: {
    tags: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      tagDeleted: false
    };
  },
  watch: {
    async tagDeleted() {
      if (this.tagDeleted) {
        await this.$store.dispatch("dbTags/deleteTag", this.id);
        this.$emit("tagDeleted");
        this.tagDeleted = false;
      }
    }
  },
  methods: {
    async deleteTag(id) {
      this.id = id;
      this.swal({
        title: "Are you sure?",
        text: "After deleting the tag, you will not be able to recover it!",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          this.tagDeleted = true;
        }
      });
    },
    showFormTag(tag) {
      this.$emit("tagUpdate", tag);
    }
  }
};
</script>

<style lang="scss" scoped>
.tags-list {
  width: 100%;
  margin: 20px 15px 0px 0px;
  @include flex-wrap(space-around);
  &__card {
    @include card;
    margin: 10px;
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
