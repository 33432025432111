<template>
  <div class="users-view" :key="keyRender">
    <h1 class="users-view__title text-center"><strong>Settings</strong></h1>
    <b-tabs fill>
      <b-tab title="Users" @click="changeTab('Users')" :active="isUsers">
        <div class="users-view__options">
          <b-button size="sm" class="new-user-button" @click="addNewUser"
            >New User</b-button
          >
        </div>
        <div class="users-content">
          <template v-if="!showNewUserForm">
            <template v-for="user in users">
              <User
                :key="user.id"
                :user="user"
                @modifyUser="modifyUserById"
                @reload="getAllUsers"
              />
            </template>
          </template>
          <template v-else>
            <Splitter class="splitters-content">
              <SplitterPanel
                :size="75"
                class="p-d-flex p-ai-center p-jc-center"
              >
                <div class="splitter-users">
                  <div class="splitter-users__content">
                    <template v-for="user in users">
                      <User
                        :key="user.id"
                        :user="user"
                        @modifyUser="modifyUserById"
                        @reload="getAllUsers"
                      />
                    </template>
                  </div>
                </div>
              </SplitterPanel>
              <SplitterPanel
                :size="25"
                class="p-d-flex p-ai-center p-jc-center"
              >
                <div class="splitter-user-form">
                  <div
                    v-if="showNewUserForm"
                    class="splitter-user-form__content"
                  >
                    <UserForm
                      @closeForm="closeForm"
                      @closeUserForm="showNewUserForm = false"
                      @userCreated="getAllUsers"
                      :roles="roles"
                    />
                  </div>
                </div>
              </SplitterPanel>
            </Splitter>
          </template>
        </div>
      </b-tab>
      <b-tab title="Tags" @click="changeTab('Tags')" :active="isTags">
        <Tags />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import User from "@/views/Payments/children/settings/children/user/User.vue";
import UserForm from "@/views/Payments/children/settings/children/user/UserForm.vue";
import Tags from "@/views/Payments/children/settings/children/tags/Tags.vue";
export default {
  name: "UsersView",
  components: {
    Splitter,
    SplitterPanel,
    User,
    UserForm,
    Tags
  },
  data() {
    return {
      showNewUserForm: false,
      showModifyUserForm: false,
      keyRender: 0,
      selectedUser: null,
      users: [],
      roles: [],
      isUsers: false,
      isTags: false
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    closeForm(event) {
      this.showNewUserForm = event;
    },
    async getAllUsers() {
      this.users = await this.$store.dispatch("users/getUsers");
      this.users = this.users.map(function(user) {
        if (user.status.app == "Active") {
          user.statusCheckbox = true;
        } else {
          user.statusCheckbox = false;
        }
        return user;
      });
      let nameTab = this.$route.name;
      this.changeTab(nameTab);
    },
    async addNewUser() {
      let results = await this.$store.dispatch("users/getRol");
      this.roles = results;
      this.$router.push({
        name: "Users",
        params: {
          option: "create"
        }
      });
      this.showNewUserForm = true;
      this.keyRender += 1;
    },
    modifyUserById(event) {
      this.$router.push({
        name: "Users",
        params: {
          option: "edit",
          id: event
        }
      });
      this.selectedUser = event;
      this.showNewUserForm = true;
      this.keyRender += 1;
    },
    changeTab(nameTab) {
      if (nameTab == "Tags") {
        this.isUsers = false;
        this.isTags = true;
        this.$router.push({ name: nameTab }).catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // logError(err);
          }
        });
      } else {
        this.isUsers = true;
        this.isTags = false;
        this.$router.push({ name: "Users" }).catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // logError(err);
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.users-view {
  width: 100%;
  margin-right: 1%;
  @include h1-font-size;
  &__title {
    margin: 20px 0px;
  }
  &__options {
    margin-top: 20px;
  }
}
.users-content {
  width: 100%;
  margin-top: 30px;
  @include flex-wrap(space-around);
}
.new-user-button {
  @include secondary-button;
}
.splitter-users {
  width: 100%;
  @extend %splitter-content;
  &__content {
    margin: 20px 15px 0px 0px;
    @include flex-wrap(space-around);
  }
}
.splitter-user-form {
  width: 100%;
  @extend %splitter-content;
  &__content {
    margin: 20px 1% 0px 15px;
  }
}
.splitters-content {
  width: 100%;
  height: 78vh;
}
::v-deep {
  .p-splitter {
    border: none;
  }
}
::v-deep .p-splitter .p-splitter-gutter {
  background-color: $color-white;
}
::v-deep .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background-color: transparent;
}
</style>
